$baseurl: '' !default;
$on-palm: 600px !default;
$on-laptop: 800px !default;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Themable colors, usually overridden in themes
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

$color-text: #111 !default;
$color-background: #f1f1f1 !default;
$color-content-background: #fdfdfd !default;
$color-highlight: #2a7ae2 !default;

$color-code-background: #eef !default;

$color-spacer: #828282 !default;
$color-spacer-highlight: lighten($color-spacer, 40%) !default;
$color-spacer-lowlight: darken($color-spacer, 25%) !default;

/////////////////////////////
// Nav Colors
////////////////////

$color-nav-bar-bg: rgba(255, 255, 255, 0.95) !default;
$color-nav-bar-bg_mobile: rgba(255, 255, 255, 0.95) !default;
$color-nav-lowlight: rgba(0, 0, 0, 0.1) !default;
$color-nav-highlight: rgba(0, 0, 0, 0.4) !default;
$color-nav-panel-bg: rgba(0, 0, 0, 0.6) !default;
$color-nav-header: white !default;
$color-nav-spacer: white !default;
$color-nav-item-normal: white !default;
$color-nav-item-select: yellow !default;
$color-nav-scrollbar-shadow: rgba(0, 0, 0, 0.3) !default;
$color-nav-scrollbar-thumb: rgba(0, 0, 0, 0.3) !default;
$color-nav-scrollbar-thumb-bg: #555 !default;

/////////////////////////////
// Nav Logo
////////////////////

$color-logo-header: #222 !default;
$color-logo-footer: #222 !default;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// General Defaults, typically won't ever change
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

$base-font-family: 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !default;
$base-font-size: 16px !default;
$base-font-weight: 400 !default;
$small-font-size: $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit: 30px !default;

$table-text-align: left !default;

// Width of the content area
$content-width: $on-laptop !default;
