nav.site-nav {
  *::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
}
