@import './navScrollbar';

.site-header {
  .wrapper {
    display: flex;

    & > a {
      display: inline-block;
      width: 100px;
      flex: 1;
    }
  }
}

@include media-query-min($on-palm) {
  nav.site-nav {
    line-height: $base-line-height * $base-font-size * 2.25;

    #menu-trigger-box,
    .menu-trigger {
      display: none;
    }

    a {
      display: block;

      &:hover {
        text-decoration: none;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    & > ul {
      display: flex;

      .menu-item-title {
        padding: 0 5px;
      }

      li {
        min-width: 150px;
        text-align: center;

        ul {
          position: absolute;
          display: none;
        }

        &.menu-type-dropdown {
          & > ul {
            & > li.menu-type-dropdown > ul {
              transform: translateY(-54px);
              right: 100%;
            }
          }

          &:hover {
            & > ul {
              display: block;
            }
          }
        }

        &.menu-type-panel {
          // Panel
          & > ul {
            padding: 15px;
            left: 0;
            right: 0;
            text-align: left;

            a.menu-item-title {
              font-size: inherit;
            }

            .menu-item-title {
              font-size: 24px;
            }

            & > li.menu-type-dropdown {
              position: relative;
              display: block;
              background: none;
              margin-right: 20px;
              padding-right: 20px;
              text-align: left;
              line-height: 35px;

              & > ul {
                overflow-y: auto;
                overflow-x: hidden;
                max-height: 163px;
                display: block;
                position: relative;
                background: none;
                line-height: 25px;

                & > li {
                  background: none;
                  flex: 1;
                  text-align: left;
                }
              }
            }
          }

          &:hover {
            li.menu-type-dropdown:hover {
              background: none;
            }

            & > ul {
              display: flex;
            }
          }

          img {
            padding-right: 20px;
          }
        }

        &.menu-type-panel,
        &.menu-type-dropdown {
          & > ul {
            backdrop-filter: blur(2px);

            ul {
              backdrop-filter: none;
            }
          }
        }
      }
    }
  }
}

@include media-query($on-palm) {
  .site-header .wrapper > a {
    flex: none;
  }

  nav.site-nav {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;

    overflow: hidden;
    min-height: $base-line-height * $base-font-size * 3;
    padding-bottom: 30px;

    input[type='checkbox'] {
      display: none;

      & ~ label {
        cursor: pointer;
        position: absolute;
        top: 16px;
        right: 15px;
        z-index: 2;
      }

      &:checked {
        & ~ ul {
          max-height: 90vh;
          right: 0;
          opacity: 1;

          overflow-x: hidden;
          overflow-y: auto;
        }
      }
    }

    .menu-trigger,
    .menu-trigger svg {
      height: 36px;
      width: 36px;
    }

    a {
      display: block;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    img {
      display: none;
    }

    & > ul {
      position: relative;
      display: flex;
      flex-direction: column;
      top: 13px;
      right: -100%;
      width: 80vw;
      max-height: 50px;
      opacity: 0;
      transition: 1s all;
      padding-bottom: 5px;

      .menu-item-title {
        padding: 0 5px;
        display: block;
      }

      & > li {
        padding-bottom: 10px;

        & > .menu-item-title {
          @include relative-font-size(2.25);
        }

        ul {
          padding-left: 10px;

          & > li {
            padding-left: 15px;
            @include relative-font-size(1.5);

            &.has-children > .menu-item-title {
              @include relative-font-size(2);
            }
          }
        }
      }
    }
  }
}
