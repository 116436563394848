/**
 * Site header
 */
.site-header {
  min-height: $spacing-unit * 1.865;

  top: 0;
  position: sticky;
  z-index: 1000;

  svg.site-title {
    width: 100px;
    height: 100px;
  }
}

/**
 * Site footer
 */
.site-footer {
  padding: $spacing-unit 0;

  svg.site-title {
    width: 75px;
    height: 75px;
  }

  .social-media-list {
    list-style: none;
    margin: 0;

    li {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    .username {
      display: none;
    }

    .svg-icon {
      height: 24px;
      width: 24px;
    }
  }

  .footer-col-wrapper {
    @include relative-font-size(0.85);
    margin-left: -$spacing-unit / 2;
    display: flex;

    .footer-col {
      margin-bottom: $spacing-unit / 2;
      padding-left: $spacing-unit / 2;
    }

    .footer-col-2 {
      flex: 1;
      display: flex;
      align-items: center;
    }

    .footer-col-3 {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    @include media-query($on-palm) {
      flex-wrap: wrap;

      .separator {
        display: table-column-group;
        opacity: 0;
      }

      .footer-col {
        margin-bottom: 0;
      }

      .footer-col-1 {
        width: 20%;
      }

      .footer-col-2 {
        width: 72%;
        flex: none;
      }

      .footer-col-3 {
        flex: 1;
        justify-content: center;
      }
    }
  }

  @include media-query($on-palm) {
    padding: 0;
    padding-bottom: 10px;
  }
}

/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}

/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
}
